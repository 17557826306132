import React from 'react'
import AWS from 'aws-sdk'

var mime = require('mime-types')

export default class StorageConnector extends React.Component {
  constructor(config) {
    super()
    this.storageClient = this.constructStorageClient(config)
  }

  constructStorageClient = (config) => {
    AWS.config.region = config.region
    AWS.config.credentials = {
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey
    }
    return new AWS.S3({
      params: { Bucket: config.bucketName },
      region: config.region
    })
  }

  consoleLogStorageClient = () => {
    console.log(this.storageClient)
  }

  constructBufferFromFile = async (file) => {
    let base64String = await this.toBase64(file)
    base64String.replace('/^data:image/w+;base64,/', '')
    return Buffer.from(base64String, 'base64')
    // return Buffer.alloc(file.size, base64String, 'base64')
  }

  toBase64 = (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  constructBlob = (chunk, type) => {
    return new Blob(chunk, {
      type
    })
  }

  constructUploadParams = (acl, filename, contentType, body, metadata) => {
    return {
      ACL: acl,
      Key: filename,
      ContentType: contentType,
      Body: body,
      Metadata: metadata
    }
  }

  uploadToStorageService = (params) => {
    this.storageClient
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        // that's how you can keep track of your upload progress
        console.log(Math.round((evt.loaded / evt.total) * 100))
      })
      .send((err) => {
        if (err) {
          console.log('ERROR WHILE UPLOADING: ', err)
          // handle the error here
        }
      })
  }

  getObjectFromStorageService = (params) => {
    this.storageClient.getObject(params, (err, data) => {
      if (err) console.log(err)
      console.log(data)
      this.download(data)
    })
  }

  download = (data) => {
    let blob = new Blob([data.Body], { type: data.ContentType })
    var url = URL.createObjectURL(blob)
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `uploadedFile.${mime.extension(data.ContentType)}`
    a.click()
    window.URL.revokeObjectURL(url)
  }

  headObjectFromStorageService = (params) => {
    return this.storageClient.headObject(params).promise()
  }

  listObjectsFromStorageService = (params) => {
    return this.storageClient.listObjectsV2(params).promise()
  }

  deleteObjectFromStorageService = (params) => {
    return this.storageClient.deleteObject(params).promise()
  }
}
